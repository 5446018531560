<template>
  <div class="container py-5">
    <div class="row text-center mt-2">
      <!-- <div class="card text-center"> -->
      <h2 class="cs-section_title mt-3">
        <b class="cs-accent_color">Locations</b>
      </h2>
      <!-- </div> -->
    </div>
    <div class="cs-search_1_wrap">
      <button class="cs-search_toggle_sm cs-toggle_btn cs-primary_color">
        <i class="fas fa-search"></i>
      </button>
      <div class="cs-search_1_out">
        <div class="d-flex flex-row justify-content-between">
          <form class="cs-search cs-style1">
            <input
              type="text"
              placeholder="Search your location"
              class="cs-search_input cs-transition_3 cs-white_bg"
              v-model="search"
            />
            <button
              @click.prevent="handleSearch"
              class="cs-search_btn cs-white cs-accent_bg_2 cs-accent_70_bg_2_hover cs-white_hover"
            >
              <i class="fas fa-search"></i>
            </button>
          </form>
          <button
            v-if="isViewAllEnabled === 1 ? true : false"
            class="cs-toolbox_btn cs-accent_bg cs-white_bg_hover cs-accent_color_hover cs-accent_color_2_hover cs-rounded border-0"
            @click.prevent="fetchAllStores"
          >
            View All Locations
            <!-- <i class="fas fa-eye"></i> -->
          </button>
        </div>
      </div>
    </div>

    <div class="cs-height_40 cs-height_lg_20"></div>

    <div class="cs-table cs-style1 table-responsive cs-white_bg">
      <table class="table">
        <thead>
          <tr>
            <th class="cs-medium cs-primary_color">Name</th>
            <th class="cs-medium cs-primary_color">Address</th>
            <th></th>
          </tr>
        </thead>
        <tbody v-for="(store, index) in paginated" :key="index">
          <tr>
            <td class="cs-accent_15_border_2">{{ store.name }}</td>
            <!-- <td class="cs-accent_15_border_2">{{store.company}}</td>
                <td class="cs-accent_15_border_2">{{store.code}}</td> -->
            <!-- <td class="cs-accent_15_border_2">
              {{
                store.location.address +
                " " +
                store.location.city +
                ", " +
                store.location.state +
                ", " +
                store.location.zipcode
              }}
            </td> -->
            <td class="cs-accent_15_border_2">
              {{ store.location.address }}
            </td>
            <!-- <td class="cs-accent_15_border_2">{{store.location.address}}, {{store.location.city}}, {{store.location.state}},  {{store.location.zipcode}}</td> -->
            <!-- :href="store.url + '/application?store=' + store.code + '&k=' + clientKey"  -->
            <td class="cs-accent_15_border_2 text-end">
              <a
                :href="
                  store.url +
                  '/application?store=' +
                  store.code +
                  '&position=CRW&company=' +
                  clientName
                "
                target="_blank"
                class="cs-btn cs-style1 cs-medium cs-primary_font cs-accent_border_2 cs-accent_color_2 cs-accent_border_hover cs-accent_bg_hover cs-white_hover"
                >Apply Now</a
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <div class="cs-height_40 cs-height_lg_40"></div>

    <!-- Pagination  -->
    <div
      class="cs-post_pagination cs-style2 cs-center cs-medium cs-primary_color"
    >
      <ul class="page-numbers d">
        <li>
          <button
            type="button"
            @click.prevent="prev"
            :disabled="current_page == 1"
            class="page-number current"
          >
            <i class="fas fa-angle-left"></i>
          </button>
        </li>
        <li>
          <button
            type="button"
            @click.prevent="next"
            :disabled="current_page == Math.ceil(filtered.length / per_page)"
            class="page-number current"
          >
            <i class="fas fa-angle-right"></i>
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  name: "Location",
  props: {
    stores: Array,
    data_count: Number,
    fetchAllStores: Function,
    isViewAllEnabled: Boolean,
  },

  methods: {
    handleSearch() {
      this.current_page = 1;

      let key = this.search.toLowerCase();
      if (key.length == 0) {
        // this.filtered = this.stores.filter((item,index) => index < this.per_page)
        this.filtered = this.stores;
      }
      this.filtered = this.stores.filter(
        (store) =>
          store.name.toLowerCase().includes(key) ||
          store.code.toLowerCase().includes(key) ||
          store.location.address.toLowerCase().includes(key)
      );
    },

    prev() {
      this.current_page--;
    },
    next() {
      this.current_page++;
    },

    // viewAllStores() {
    //   this.$emit("view-all");
    // },
  },

  watch: {
    // stores(){
    //     // this.filtered = this.stores.filter((item,index) => index < this.per_page)
    //     this.filtered = this.stores
    // }
    stores() {
      if (this.stores.length > 0) {
        this.filtered = this.stores;
      }
    },
  },

  data() {
    return {
      filtered: [],
      search: null,
      per_page: 10,
      current_page: 1,
      clientKey: process.env.VUE_APP_CLIENT_KEY,
      clientName: process.env.VUE_APP_CLIENT,
    };
  },

  computed: {
    indexStart() {
      return (this.current_page - 1) * this.per_page;
    },
    indexEnd() {
      return this.indexStart + this.per_page;
    },
    paginated() {
      return this.filtered.slice(this.indexStart, this.indexEnd);
    },

    filteredList() {
      return this.filtered.filter((store) => {
        return store.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },

  setup() {},
};
</script>
