<template>
  <!-- Start Preloader -->
  <div class="cs-preloader cs-accent_color cs-white_bg">
    <div class="cs-preloader_bg cs-center cs-accent_4_bg">
      <div class="cs-preloader_in cs-accent_15_border">
        <img src="../assets/app-landing/amsqrLogo.png" alt="Logo" />
      </div>
    </div>
  </div>
  <!-- End Preloader -->
  <!-- Start Header Section -->
  <header
    class="cs-site_header cs-style1 cs-type2 cs-sticky-header cs-primary_font wow fadeIn"
    data-wow-duration="1s"
    data-wow-delay="0.2s"
  >
    <div class="cs-main_header">
      <div class="container">
        <div class="cs-main_header_in">
          <div class="cs-main_header_left">
            <a class="cs-site_branding" href="/">
              <img :src="wp_cms + `storage/${client.image}`" alt="Logo"
            /></a>
          </div>
          <div class="cs-main_header_center">
            <div class="cs-nav">
              <ul
                class="cs-nav_list"
                style="margin-right: 20px; font-size: medium"
              >
                <li>
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('home')"
                    class="cs-smoth_scroll"
                    >Home</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('service')"
                    class="cs-smoth_scroll"
                    >Services</a
                  >
                </li>
                <li v-if="client.show_about === 1">
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('about')"
                    class="cs-smoth_scroll"
                    >About</a
                  >
                </li>
                <li v-if="client.show_stores === 1">
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('location')"
                    class="cs-smoth_scroll"
                    >Locations</a
                  >
                </li>
                <li v-if="client.show_careers === 1">
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('careers')"
                    class="cs-smoth_scroll"
                    >Careers</a
                  >
                </li>
                <li>
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('team')"
                    class="cs-smoth_scroll"
                    >Team</a
                  >
                </li>
                <li v-if="client.show_contact === 1">
                  <a
                    href="#"
                    @click.prevent="scrollMeTo('contact')"
                    class="cs-smoth_scroll"
                    >Contact</a
                  >
                </li>
                <li v-if="client.show_gallery === 1">
                  <a href="#" @click.prevent="scrollMeTo('gallery')">Events</a>
                </li>
              </ul>
            </div>
          </div>
          <div class="cs-main_header_right">
            <div class="cs-toolbox">
              <a
                v-if="client != undefined"
                :href="client.url"
                target="_blank"
                class="cs-btn cs-style6 cs-btn_md cs-rounded cs-primary_font cs-medium cs-accent_bg cs-white cs-accent_border cs-accent_10_bg_hover cs-accent_30_border_hover cs-smoth_scroll"
              >
                <span class="cs-btn_text" style="font-size: small"
                  >Now Hiring</span
                >
              </a>
              <a
                v-if="client != undefined && client.employee_portal_url"
                :href="client.employee_portal_url"
                target="_blank"
                class="cs-toolbox_btn cs-accent_bg cs-white_bg_hover cs-accent_color_hover cs-accent_color_2_hover cs-rounded"
              >
                <span class="cs-btn_text" style="font-size: small"
                  >Employee Portal</span
                >
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </header>
  <!-- End Header Section -->

  <!-- Start Slider -->
  <div class="cs-hero cs-style6 cs-center" ref="home">
    <div class="container">
      <div
        v-if="client.slider !== undefined"
        class="cs-hero_text wow fadeIn"
        data-wow-duration="1s"
        data-wow-delay="0.3s"
      >
        <div class="cs-hero_subtitle">{{ client.slider[0]["title"] }}</div>
        <h1 class="cs-hero_title">
          <b class="cs-accent_color">
            <div v-html="client.slider[0]['description']"></div>
          </b>
        </h1>
        <div class="cs-hero_btns">
          <a
            v-if="client != undefined"
            :href="client.url"
            target="_blank"
            class="cs-btn cs-style6 cs-btn_lg cs-rounded cs-primary_font cs-medium cs-accent_bg cs-white cs-accent_border cs-accent_10_bg_hover cs-accent_30_border_hover"
          >
            <span class="cs-btn_text">Now Hiring</span>
            <span class="cs-btn_icon cs-center cs-white"
              ><i class="fas fa-angle-double-right"></i
            ></span>
          </a>
        </div>
      </div>
    </div>
    <div
      class="cs-hero_img cs-parallax wow fadeInLeft"
      data-wow-duration="1s"
      data-wow-delay="0.4s"
    >
      <div class="cs-to_right" v-if="client.slider !== undefined">
        <img :src="wp_cms + `storage/${client.slider[0]['image']}`" />
      </div>
    </div>
  </div>
  <!-- End Slider -->

  <!-- Start Service -->
  <div ref="service" class="py-5 mt-5">
    <!-- <div class="cs-height_175 cs-height_lg_80"></div> -->
    <div class="container">
      <div
        class="cs-section_heading cs-style2 cs-size2 text-center wow fadeInUp"
        data-wow-duration="1s"
        data-wow-delay="0.2s"
      >
        <div class="cs-section_subtitle">What we do for you</div>
        <h2 v-if="client !== undefined" class="cs-section_title">
          Why <b class="cs-accent_color">{{ client.name }}</b
          >.
        </h2>
      </div>
      <div class="cs-height_65 cs-height_lg_45"></div>
    </div>
    <div class="container">
      <div
        class="row justify-content-center align-items-stretch"
        v-if="client.service !== undefined"
      >
        <div
          class="col-lg"
          v-for="value in client.service"
          v-bind:key="value.id"
        >
          <div
            v-if="client.service !== undefined && value['status'] === 1"
            class="d-flex flex-column h-100"
          >
            <div
              class="cs-icon_box cs-style3 cs-color1 text-center wow fadeIn flex-grow-1"
              data-wow-duration="1s"
              data-wow-delay="0.25s"
            >
              <div class="cs-icon_box_icon cs-center">
                <img :src="wp_cms + `storage/${value['image']}`" alt="Logo" />
              </div>
              <h2 class="cs-icon_box_title cs-semi_bold">
                {{ value["title"] }}
              </h2>
              <div
                class="cs-icon_box_subtitle"
                v-html="value['description']"
              ></div>
            </div>
            <div class="cs-height_30 cs-height_lg_30"></div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="cs-height_110 cs-height_lg_50"></div> -->
  </div>
  <!-- End Service -->

  <!-- Start About -->
  <div
    v-if="client !== undefined && client.show_about === 1"
    class="cs-shape_bg cs-style4"
    ref="about"
  >
    <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-5">
          <div
            class="text-center wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <img
              :src="wp_cms + `storage/${client.about.image}`"
              class="cs-about_img cs-style1"
            />
          </div>
          <div class="cs-height_0 cs-height_lg_50"></div>
        </div>

        <div class="col-lg-6 offset-lg-1">
          <div class="cs-vertical_middle">
            <div class="cs-vertical_middle_in">
              <div
                class="cs-text_box cs-style1 cs-size1 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <div class="cs-text_box_subtitle">About Us</div>
                <h2 class="cs-text_box_title">
                  <b class="cs-accent_color">{{ client.about.title }}</b>
                </h2>
                <div class="cs-height_25 cs-height_lg_25"></div>
                <div class="cs-text_box_text" v-html="companyAboutDesc"></div>

                <div class="cs-height_25 cs-height_lg_20"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
  </div>
  <!-- End About -->

  <!-- Start Locations -->
  <div class="cs-accent_4_bg" ref="location">
    <!-- Location -->

    <Location
      v-if="client.show_stores === 1"
      :stores="stores"
      :data_count="per_page"
      :fetchAllStores="fetchAllStores"
      :isViewAllEnabled="isViewAllEnabled"
    />

    <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
  </div>
  <!-- End Locations -->

  <!-- Start Positions  -->
  <div ref="careers">
    <Positions
      v-if="client.show_careers === 1"
      :Stores="stores"
      :Per_Page="per_page"
    />
  </div>
  <!-- End Positions  -->

  <!-- Start Employee Spotlight -->
  <div ref="team" class="py-5">
    <div class="container">
      <!-- <div class="cs-height_175 cs-height_lg_80"></div> -->
      <div class="row" v-if="client.team !== undefined">
        <div
          class="cs-section_heading cs-style2 cs-size2 text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <h2 class="cs-section_title">
            <b class="cs-accent_color" v-if="client.team !== undefined"
              >Employee Spotlight</b
            >
          </h2>
        </div>
        <div class="cs-height_65 cs-height_lg_45"></div>
      </div>
    </div>
    <div class="container">
      <div class="" v-if="client.team !== undefined">
        <div class="d-flex gap-3 flex-wrap flex-lg-nowrap align-items-stretch">
          <div
            class="col-lg"
            v-for="value in client.team"
            v-bind:key="value.id"
          >
            <div
              v-if="client.team !== undefined && value['status'] === 1"
              class="h-100"
            >
              <div
                class="cs-icon_box cs-style3 cs-color1 text-left wow fadeIn h-100"
                data-wow-duration="1s"
                data-wow-delay="0.25s"
              >
                <div class="cs-icon_box_icon cs-center">
                  <img :src="wp_cms + `storage/${value['image']}`" />
                  <div class="cs-con_box-shape cs-accent_bg"></div>
                </div>
                <h2 class="cs-icon_box_title cs-semi_bold">
                  {{ value["title"] }}
                </h2>
                <div
                  class="cs-icon_box_subtitle"
                  v-html="companyTeamDesc(value['description'])"
                ></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="cs-height_110 cs-height_lg_50"></div> -->
  </div>

  <!-- End Exmployee Spotlight -->

  <!-- Start Company Benefits -->
  <div class="cs-shape_bg cs-style4" v-if="client != undefined">
    <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-6 offset-lg-1">
          <div class="cs-vertical_middle">
            <div class="cs-vertical_middle_in">
              <div
                class="cs-text_box cs-style1 cs-size1 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <h2 class="cs-text_box_title">
                  <b class="cs-accent_color">{{ client.benefit_title }}</b>
                </h2>
                <div class="cs-height_25 cs-height_lg_25"></div>

                <div v-html="companyBenefitsDesc"></div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-lg-5">
          <div
            class="text-center wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <img
              :src="wp_cms + `storage/${client.benefit_image}`"
              alt="Company Image"
            />
          </div>
          <div class="cs-height_0 cs-height_lg_50"></div>
        </div>
      </div>
    </div>
    <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
  </div>
  <!-- End Company Benefits -->

  <!-- Start Company Culture -->
  <div class="cs-shape_bg cs-style4" v-if="client != undefined">
    <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
    <div class="container py-5">
      <div class="row">
        <div class="col-lg-5">
          <div
            class="text-center wow fadeInLeft"
            data-wow-duration="1s"
            data-wow-delay="0.2s"
          >
            <img
              :src="wp_cms + `storage/${client.culture_image}`"
              alt="Company Image"
            />
          </div>
          <div class="cs-height_0 cs-height_lg_50"></div>
        </div>

        <div class="col-lg-6 offset-lg-1">
          <div class="cs-vertical_middle">
            <div class="cs-vertical_middle_in">
              <div
                class="cs-text_box cs-style1 cs-size1 wow fadeIn"
                data-wow-duration="1s"
                data-wow-delay="0.3s"
              >
                <h2 class="cs-text_box_title">
                  <b class="cs-accent_color">{{ client.culture_title }}</b>
                </h2>
                <div class="cs-height_25 cs-height_lg_25"></div>

                <div class="cs-height_25 cs-height_lg_20"></div>

                <div class="cs-height_35 cs-height_lg_25"></div>

                <div v-html="companyCultureDesc"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="cs-height_140 cs-height_lg_80"></div> -->
  </div>
  <!-- End Company Culture -->
  <!-- Start Contact -->
  <div ref="contact" v-if="client.show_contact === 1">
    <Contact />
  </div>
  <!-- End Contact -->

  <!-- Start Employee Gallery -->
  <!-- <div ref="gallery">
    <div class="container pt-5">
      <div class="row" v-if="client.gallery !== undefined">
        <div
          class="cs-section_heading cs-style2 cs-size2 text-center wow fadeInUp"
          data-wow-duration="1s"
          data-wow-delay="0.2s"
        >
          <h2 class="cs-section_title">
            <b class="cs-accent_color" v-if="client.gallery !== undefined"
              >Gallery</b
            >
          </h2>
        </div>
      </div>
    </div>
    <div class="container py-5">
      <div class="row card border-0" v-if="client.gallery !== undefined">
        <div class="d-flex justify-content-between">
          <div
            class="col-lg mx-2"
            v-for="(element, index) in client.gallery"
            :key="index"
          >
            <h4 class="cs-icon_box_title cs-semi_bold">
              {{ element["title"] }}
            </h4>
            <div v-if="Array.isArray(element['image'])">
              <div v-for="(img, imgIndex) in element['image']" :key="imgIndex">
                <div
                  class="cs-icon_box cs-style3 cs-color1 text-left wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="cs-icon_box_icon cs-center">
                    <img :src="wp_cms + 'storage/' + img" />
                    <div class="cs-con_box-shape cs-accent_bg"></div>
                  </div>
                </div>
                <div class="cs-height_30 cs-height_lg_30"></div>
              </div>
            </div>
            <div v-else>
              <div
                v-for="(img, imgIndex) in JSON.parse(element['image'])"
                :key="imgIndex"
              >
                <div
                  class="cs-icon_box cs-style3 cs-color1 text-left wow fadeIn"
                  data-wow-duration="1s"
                  data-wow-delay="0.25s"
                >
                  <div class="cs-icon_box_icon cs-center">
                    <img :src="wp_cms + 'storage/' + img" />
                    <div class="cs-con_box-shape cs-accent_bg"></div>
                  </div>
                </div>
                <div class="cs-height_30 cs-height_lg_30"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <!-- End Exmployee Gallery -->

  <!-- Start Gallery -->
  <div ref="gallery" v-if="client.show_gallery === 1">
    <Gallery v-if="client.show_gallery === 1" :albums="client?.gallery" />
  </div>
  <!-- End Gallery -->

  <!-- Footer Section -->
  <footer
    class="cs-footer cs-style2 cs-bg cs-accent_4_bg wow fadeIn"
    data-wow-duration="1s"
    data-wow-delay="0.2s"
    data-src="../assets/app-landing/footer-bg.png"
  >
    <div class="cs-white_bg">
      <div class="cs-copyright cs-accent_8_bg">
        <div class="container">
          <div class="cs-copyright_in">
            © {{ currentYear }}. All rights reserved by {{ client.name }}.
          </div>
        </div>
      </div>
    </div>
  </footer>
  <!-- Footer Section -->
</template>

<script>
import Positions from "../components/positions.vue";
import Location from "../components/location.vue";
import { marked } from "marked";
import Contact from "../components/contact.vue";
import Gallery from "../components/gallery.vue";

export default {
  components: {
    Location,
    Positions,
    Contact,
    Gallery,
    // GalleryView
  },
  props: {
    msg: String,
    client: {},
  },
  mounted() {
    // console.log(this.client);
    // `recruit-stores?lat=40.5753817&lng=-74.3223703&radius=10&key=${this.key}`
    // this.axios
    //   // .get(this.career_url + "recruit-stores?key=" + this.key)
    //   .get(
    //     this.career_url +
    //       `recruit-stores?lat=40.5753817&lng=-74.3223703&radius=${this.defaultLocRadius}&key=${this.key}`
    //   )
    //   .then((response) => {
    //     // console.log(response);
    //     this.stores = response.data.data;
    //     this.filtered = this.stores;

    //     this.aboutDesc = this.client?.about?.description;
    //     this.benefitsDesc = this.client?.benefits_description;
    //     this.cultureDesc = this.client?.culture_description;
    //   });

    this.getCurrentLocation()
      .then(({ latitude, longitude }) => {
        // Check if latitude and longitude are defined
        if (latitude && longitude) {
          //this.isViewAllEnabled = true;
          this.fetchStoresWithLocation(latitude, longitude);
        } else {
          //this.isViewAllEnabled = false;
          this.fetchAllStores();
        }
      })
      .catch((error) => {
        console.error("Failed to get current location:", error);
        this.fetchAllStores();
      });

    this.albums = this.client?.gallery;
  },
  data() {
    return {
      wp_cms: process.env.VUE_APP_API_URL,
      career_url: process.env.VUE_APP_CAREER_API_URL,
      key: process.env.VUE_APP_CLIENT_KEY,
      defaultLocRadius: process.env.VUE_APP_DEFAULT_RADIUS,
      client_name: process.env.VUE_APP_CLIENT,

      client_access_key: process.env.VUE_APP_FE_ACCESS_CLIENT_URL_AND_KEY,

      test: {},
      stores: [],
      search: "",
      filtered: [],
      albums: [],
      per_page: 5,
      mode: "history",

      currentYear: new Date().getFullYear(),

      benefitsDesc: "",
      cultureDesc: "",
      aboutDesc: "",
      isViewAllEnabled: false,
    };
  },

  computed: {
    companyBenefitsDesc() {
      return marked(this.benefitsDesc);
    },
    companyCultureDesc() {
      return marked(this.cultureDesc);
    },
    companyAboutDesc() {
      return marked(this.aboutDesc);
    },
  },

  methods: {
    handleSearch() {
      let key = this.search.toLowerCase();
      if (key.length == 0) {
        this.filtered = this.stores;
      }
      this.filtered = this.stores.filter((store) =>
        store.location.address.toLowerCase().includes(key)
      );
    },

    scrollMeTo(refName) {
      var element = this.$refs[refName];
      var top = element.offsetTop;
      window.scrollTo(0, top);
    },

    companyTeamDesc(params) {
      return marked(params);
    },

    requestLocation() {
      return new Promise((resolve, reject) => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(
            (position) => {
              const latitude = position.coords.latitude;
              const longitude = position.coords.longitude;
              console.log(`Latitude: ${latitude}, Longitude: ${longitude}`);
              resolve({ latitude, longitude });
            },
            (error) => {
              console.error(error);
              reject(error);
            }
          );
        } else {
          console.error("Geolocation is not supported by this browser.");
          reject(new Error("Geolocation is not supported by this browser."));
        }
      });
    },

    getCurrentLocation() {
      return this.requestLocation().catch(() => {
        // Return null coordinates if geolocation fails or is denied
        return { latitude: null, longitude: null };
      });
    },

    fetchStoresWithLocation(latitude, longitude) {
      // latitude = 40.5753817;
      // longitude = -74.3223703;
      this.axios
        .get(
          `${this.career_url}recruit-stores?lat=${latitude}&lng=${longitude}&radius=${this.defaultLocRadius}&key=${this.key}`
        )
        .then((response) => {
          // alert(response?.data?.msg);
          // console.log(response, "-----------------RESPONSE-2");
          this.stores = response.data.data;
          this.filtered = this.stores;

          this.aboutDesc = this.client?.about?.description;
          this.benefitsDesc = this.client?.benefits_description;
          this.cultureDesc = this.client?.culture_description;

          this.isViewAllEnabled = response.data.isLocationFiltered;
        });
    },

    fetchAllStores() {
      this.axios
        .get(`${this.career_url}recruit-stores?key=${this.key}`)
        .then((response) => {
          // alert(response?.data?.msg);
          // console.log(response, "------------------RESPONSE-1");
          this.stores = response.data.data;
          this.filtered = this.stores;

          this.aboutDesc = this.client?.about?.description;
          this.benefitsDesc = this.client?.benefits_description;
          this.cultureDesc = this.client?.culture_description;

          this.isViewAllEnabled = response.data.isLocationFiltered;
        });
    },
  },
};
</script>
